function desplegarMenu() {
  if (document.getElementById("navHamburguesa").src == "https://trepico.com.gt/images/icons/Hamburguesa.png") {
    document.getElementById("navHamburguesa").src = "https://trepico.com.gt/images/icons/Salir.svg";
    document.getElementById("ListaMenu").style.display = "inline-block";
  }
  else {
    document.getElementById("navHamburguesa").src = "https://trepico.com.gt/images/icons/Hamburguesa.png";
    document.getElementById("ListaMenu").style.display = "none";
  }
}

window.addEventListener('resize', function (event) {
  if (this.window.innerWidth > 860) {
    document.getElementById("ListaMenu").style.display = "inline-block";
    document.getElementById("navHamburguesa").src = "https://trepico.com.gt/images/icons/Salir.svg";
  }
}, true);

function li_clicked() {
  if (this.window.innerWidth > 860) {
    document.getElementById("ListaMenu").style.display = "inline-block";
    document.getElementById("navHamburguesa").src = "https://trepico.com.gt/images/icons/Salir.svg";
  } else {
    document.getElementById("navHamburguesa").src = "https://trepico.com.gt/images/icons/Hamburguesa.png";
    document.getElementById("ListaMenu").style.display = "none";
  }
}
